$ = jQuery;

$( document ).ready( function( $ ) {
 "use strict";

 if ($(window).width() < 1200) {
  $( '.hamburger' ).click( function() {
    $( this ).toggleClass( 'is-active' );
    if($('body').hasClass('menu-open')) {
      $('body').removeClass('menu-open');
      $('.main-navigation').removeClass( 'toggled' );
      $('.mob-menu').slideUp();
    } else {
      $('body').addClass('menu-open');
      $('.main-navigation').addClass( 'toggled' );
      $('.mob-menu').slideDown()
    }
  } );
}


$( '#primary-menu > .menu-item-has-children' ).click( function( e ) {

  if ( $( e.target ).parent().hasClass( 'nav' ) ) {
    $( this ).toggleClass( 'open' );

    if ( $( this ).hasClass( 'open' ) ) {
      $( this ).find( '> ul' ).slideDown();
    } else {
      $( this ).find( '> ul' ).slideUp();
    }
  }
} );

$( '.sub-menu > .menu-item-has-children' ).click( function( e ) {
  if ( $( e.target ).parent().hasClass( 'sub-menu' ) ) {

    $( this ).toggleClass( 'open' );

    if ( $( this ).hasClass( 'open' ) ) {
      $( this ).find( '> ul' ).slideDown();
    } else {
      $( this ).find( '> ul' ).slideUp();
    }
  }
} );

// Clearance items load

// if(('.container-product--clearance').length) {

//   $('.clearance-load').click(function(e){
//     e.preventDefault();
//     var items = $('.item-hidden');
//     var itemsLength = items.length;
//     $(".item-hidden").slice(0, 9).toggleClass("item-hidden item-visible").slideDown();
//     $(".item-visible").last().addClass('last');
//     if(itemsLength < 10 ) {
//       $('.clearance-load').css('display', 'none');
//     }
//   });
// }

//FAQ

$( '.faq-title' ).click( function() {
  if ( ! $( this ).hasClass( 'open' ) ) {
    $('.faq-text').slideUp();
    $('.faq-title, .faq-item').removeClass('open');
    $( this ).addClass( 'open' );
    $( this ).parent().addClass( 'open' );
    $( this ).next().slideDown();
  } else {
    // $( this ).next().slideUp();
    $('.faq-text').slideUp();
    $('.faq-title, .faq-item').removeClass('open');
  }
} );


$('.counter').each(function() {
  var $this = $(this),
  countTo = $this.attr('data-count');

  $({ countNum: $this.text()}).animate({
    countNum: countTo
  },

  {

    duration: 1000,
    easing:'linear',
    step: function() {
      $this.text(Math.floor(this.countNum));
    },
    complete: function() {
      $this.text(this.countNum);
    }

  });
});
} );

//Fixed header

$(window).scroll(function(){
  if($(window).scrollTop() > 150) {
    $('body').addClass('scroll');
  } else {
    $('body').removeClass('scroll');
  }
});

$('.hero-slider').on('init', function(event, slick){
  var dots = $( '.slick-dots li' );
  dots.each( function( k, v){
    $(this).find( 'button' ).addClass( 'heading'+ k );
  });
  var items = slick.$slides;
  items.each( function( k, v){
    var text = $(this).find( 'h2' ).text();
    $( '.heading' + k ).text(text);
  });
});

$("#link-video").on('click', function() {
    $('html, body').animate({
        scrollTop: $("#video-block").offset().top - 40
    }, 2000);
});



$('.hero-slider').slick({
  lazyLoad: 'progressive',
  dots: true,
  focusOnSelect: true,
  infinite: true,
  arrows: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
  {
    breakpoint: 767,
    settings: {
      lazyLoad: 'progressive',
      dots: false,
      focusOnSelect: true,
      infinite: true,
      arrows: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }
  ]
});

var $status = $('.pagingInfo');
var $slickElement = $('.product-card-slider-for');

$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    var i = (currentSlide ? currentSlide : 0) + 1;
    $status.text(i + '/' + slick.slideCount);
  });

$('.product-card-slider-for').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  autoplay: true,
  asNavFor: '.product-card-slider-nav'
});

$('.product-card-slider-nav').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.product-card-slider-for',
  dots: false,
  arrows: true,
  autoplay: true,
  focusOnSelect: true,
  responsive: [
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.product-card-slider-for',
      dots: false,
      arrows: true,
      autoplay: true,
        // centerMode: true,
        focusOnSelect: true,
      }
    }
    ]
});

$('.one-trip-containres-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  autoplay: true,
  focusOnSelect: true,
  fade: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        lazyLoad: 'progressive',
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 300,
      }
    }
  ]
});


$('.product-card-gallery').each(function() {
  $(this).magnificPopup({
    delegate: '.slick-slide a',
    type: 'image',
    gallery: {
      enabled:true
    }
  });
});

$('.slider-list').slick({
  lazyLoad: 'progressive',
  dots: true,
  focusOnSelect: true,
  infinite: true,
  arrows: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      dots: true,
      arrows: false
    }
  },
  {
    breakpoint: 767,
    settings: {
      lazyLoad: 'progressive',
      arrows: false,
      focusOnSelect: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }
  ]
});

$('.slider-list-wide').slick({
  lazyLoad: 'progressive',
  dots: false,
  focusOnSelect: true,
  infinite: true,
  arrows: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  },
  {
    breakpoint: 767,
    settings: {
      lazyLoad: 'progressive',
      focusOnSelect: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }
  ]
});


$('.locations-slider').slick({
  lazyLoad: 'progressive',
  infinite: true,
  arrows: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }
  ]
});

$('.items-list-with-round-images-slider').slick({
  lazyLoad: 'progressive',
  infinite: true,
  arrows: true,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    }
  }
  ]
});

$('.text-block .gallery').slick({
  lazyLoad: 'progressive',
  infinite: true,
  arrows: false,
  dots: true,
  speed: 300,
  autoplay: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
});


$('.masonry-with-columns').each(function() {
  $(this).magnificPopup({
    delegate: '.picture a',
    type: 'image',
    gallery: {
      enabled:true
    }
  });
});

/**
* Nice select
*/
$('.contact-city-col--form select').niceSelect();

// Blocking right click at website
// document.onkeydown = function(e) {
//   if(event.keyCode == 123) {
//   return false;
//   }
//   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){
//   return false;
//   }
//   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){
//   return false;
//   }
//   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){
//   return false;
//   }
// }

if ($(window).width() > 992) {
  $(window).load(function() {
    var boxes = $( '.sidebar-inner' );
    var blockHt = $( '#post-date' ).outerHeight();
    var blockHb = $( '#post-title' ).outerHeight();
    boxes.css('top', blockHt + blockHb);
    boxes.removeClass('hidden');

  });
}